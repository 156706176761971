/* eslint-disable no-underscore-dangle */ /* eslint-disable no-console */
<template>
  <div class="testimonial-section">
    <headerCustom></headerCustom>
    <div class="template main-content sidenav-part ng-scope">
      <h3>Leads</h3>
      <div class="widget">
        <div class="testimonial-content">
          <b-form
            v-promise-btn="{ action: 'submit' }"
            @submit.prevent="saveLeads"
            @reset.prevent="resetForm()"
          >
            <div>
              <div class="font-18 py-3">
                Information
                <!-- <span class="font-12">(* Required information)</span> -->
              </div>
              <!-- name -->
              <div>
                <b-form-group>
                  <label>Name</label>
                  <b-input v-model="leads.name" placeholder="Enter Name"></b-input>
                </b-form-group>
              </div>
              <div>
                <b-form-group>
                  <label>Email</label>
                  <b-input type="email" v-model="leads.email" placeholder="Enter Email"></b-input>
                </b-form-group>
              </div>
              <div>
                <b-form-group>
                  <label>Phone</label>
                  <b-input
                    type="number"
                    v-model="leads.phone"
                    placeholder="Enter Phone Number"
                  ></b-input>
                </b-form-group>
              </div>
            </div>
            <div class="pt-2">
              <b-form-group>
                <label>Check In</label>
                <div>
                  <input type="date" v-model="leads.checkIn" />
                </div>
              </b-form-group>
            </div>
            <div class="pt-2">
              <b-form-group>
                <label>Check Out</label>
                <div>
                  <input type="date" v-model="leads.checkOut" />
                </div>
              </b-form-group>
            </div>
            <div>
              <b-form-group>
                <label>No Of Guest</label>
                <b-input
                  type="number"
                  v-model="leads.noOfGuest"
                  placeholder="Enter No of Guest"
                ></b-input>
              </b-form-group>
            </div>
            <div>
              <b-form-group>
                <label>Message</label>
                <b-form-textarea
                  v-model="leads.message"
                  id="textarea"
                  placeholder="Enter message"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>
            </div>
            <div>
              <b-form-group>
                <label>Hotel Name</label>
                <multiselect
                  :taggable="true"
                  track-by="name"
                  :options="hotelList_options"
                  v-model.trim="leads.hotel"
                  :custom-label="customLabel"
                  placeholder="Enter Hotel Name"
                ></multiselect>
              </b-form-group>
            </div>
            <div v-if="showError" class="error-text pt-2 text-center">{{ message1 }}</div>
            <div class="d-flex justify-content-center pt-3">
              <div>
                <b-button variant="success" type="submit">Save</b-button>
              </div>
              <div>
                <b-button variant="danger" type="reset" class="ml-5">Cancel</b-button>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import headerCustom from "@/components/Header.vue";
import service from "@/service/apiservice";
import { validationMixin } from "vuelidate";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    headerCustom,
    Multiselect
  },
  mixins: [validationMixin],
  data() {
    return {
      leads: {
        name: "",
        email: "",
        phone: "",
        checkIn: "",
        checkOut: "",
        message: "",
        hotel: "",
        leadThrough: "WedDestinations"
      },
      hotelList_options: [],
      showError: false,
      message1: ""
    };
  },

  created() {
    this.getAllHotelList();
  },
  methods: {
    resetForm() {
      this.$router.push({ name: "viewUserLead" });
    },
    saveLeads(data) {  
      service.saveLeadAdmin(this.leads, resultData => {
        if (resultData.status == 200) {
          this.$toaster.success("Lead saved");
          this.$router.push({ name: "viewUserLead" });
        } else {
        }
      });
    },
    getAllHotelList() {
      service.getAllHotelList(result => {
        if (result.status == 200) {
          this.hotelList_options = result.data;
        }
      });
    },
    customLabel({ name }) {
      return `${name}`;
    }
  }
};
</script>
<style lang="scss">
@import "src/assets/scss/variables";
.testimonial-section {
  .testimonial-content {
    padding: 20px 20px;
  }
}
.error-text {
  font-size: 13px;
  color: #dc3545;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
